<template>
  <vx-card
    class="data-thumb-block cursor-pointer"
    @click="$router.push(`/monitoring/tests/${data.id}`)"
    @mouseover="hover = true" @mouseout="hover = false">
    <div class="vx-row -mx-2 flex-no-wrap">
      <div class="col-thumb vx-col px-2 w-48 flex items-center justify-center pl-0 -ml-2"
        :style="{ 'background-image': `url(${screenshot || '/assets/images/placeholder.png'})` }">
        <transition name="fade">
          <div v-show="hover">
            <div class="flex">
              <vs-button
                radius
                icon-pack="feather"
                icon="icon-play"
                color="success"
                class="mr-2"
                @click.stop="$todo">
              </vs-button>
              <vs-button
                radius
                icon-pack="feather"
                icon="icon-edit"
                color="primary"
                @click.stop="$router.push(`/monitoring/tests/${data.id}`)">
              </vs-button>
            </div>
          </div>
        </transition>
      </div>
      <div class="vx-col px-2 pl-4 flex-grow flex flex-col">
        <div class="flex justify-between mb-3 flex-grow">
          <div class="max-w-xs truncate">
            <span class="font-semibold text-sm">{{ data.title || data.name || 'No title' }}</span>
            <p v-if="data.url" class="font-light text-xs my-2 text-primary max-w-xs truncate">
              <vs-icon icon-pack="feather" icon="icon-link" class="mr-1 text-xs"></vs-icon>
              {{ data.url | urlPath }}
            </p>
            <p class="font-light text-xs max-w-xs truncate">
              <vs-icon icon-pack="feather" icon="icon-clock" class="mr-1 text-xs"></vs-icon>
              {{ data.entity.dateCreated | moment }}
            </p>
          </div>
          <!-- <div class="ml-auto flex items-center self-start">
            <vs-tooltip text="Run Test">
              <vs-button size="small" icon-pack="feather" icon="icon-play" color="success" type="border" border-radius @click="$todo"></vs-button>
              <span></span>
            </vs-tooltip>
            <vs-tooltip text="Run Test">
              <vs-button size="small" icon-pack="feather" icon="icon-play" color="success" type="border" border-radius @click="$todo"></vs-button>
              <span></span>
            </vs-tooltip>
          </div> -->
        </div>
        <vs-divider class="mt-0 mb-2" />
        <div class="flex text-xs">
          <span class="uppercase">6 Test Cases</span>
          <span class="ml-5 uppercase text-success">4 Passed</span>
          <span class="ml-5 uppercase text-danger">1 Failed</span>
          <span class="ml-5 uppercase">1 Disabled</span>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    config: {
      type: Object
    },
  },
  data() {
    return {
      hover: false,
      // TO BE UPDATED
      screenshot: '',
    }
  },
  computed: {
    
  },
  methods: {
    async deleteCapture() {
      this.$todo();
    }
  },
  created() {}
}
</script>

