<template>
  <div class="vx-row">
    <div class="vx-col w-full md:w-2/3">
      <vx-data-list
        class="vx-data-list-captures"
        ref="vxDataList"
        :hoverFlat="true"
        :data="tests"
        :columns="columns"
        :search="false"
        :config="{
          disablePager: true,
          selectable: false
        }"
        @filter="filter"
      />
    </div>
    <div class="vx-col w-full md:w-1/3">
      <SubscriptionQuote class="mt-5 mb-base" />
      <vx-card class="mb-base" title="active">
        <template slot="title">
          <h6 class="font-semibold">Detect changes made to web pages</h6>
        </template>
        <div class="text-sm">
          <p class="mb-5">
            Don't miss an update, market signal, important news, or even small adjustments.
          </p>
          <p>
            Create an automation for each page you want to monitor. As soon as a visual change is detected, you receive a notification and you can
            easily see what has changed.
          </p>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import SubscriptionQuote from '@/components/SubscriptionQuote';
import TestCaseThumbnail from '@/views/monitoring/components/TestCaseThumbnail';
import Paging from '@/entity/Paging';
import { mapState } from 'vuex';

export default {
  components: {
    SubscriptionQuote
  },
  data() {
    return {
      columns: [
        {
          component: TestCaseThumbnail
        }
      ],
      list: {
        items: [
          {
            id: 92948,
            key: 'ypKpn0q',
            title: '',
            script: '',
            detectedChangePercent: 0,
            detectedChangeFromReferencePercent: 0,
            dateCaptured: '2020-08-21T00:00:53.000Z',
            status: '200',
            warcPath: '',
            harPath: '',
            htmlPath: '',
            captureHtml: false,
            captureHar: false,
            captureWarc: false,
            captureScreencast: false,
            passed: true,
            metaDescription:
              'Browse our range of ski and snowboard gear, snow boots and more. Huge range of brands to choose from in-store and online at great prices. Shop with us!',
            documentDownloadTimeMs: 1103,
            entity: {
              dateCreated: '2020-08-21T00:00:52.879Z',
              dateDeleted: null,
              dateLastUpdated: '2020-08-21T00:01:05.000Z'
            },
            screenshots: [
              {
                id: 88871,
                key: 'ea6Ov1T',
                detectedChangePercent: 1,
                step: 0,
                reference: '',
                caption: '',
                passed: false,
                url: '',
                screenShotPath: 'https://pagewitness-chromda-files.s3.amazonaws.com/42a377be2faff6e0b90f8f2046e97ea336acbb9f_1440x960.png',
                viewPort: { width: 1440, height: 960 },
                image: {
                  thumbnail: 'https://screenshots.pagewitness.com/42a377be2faff6e0b90f8f2046e97ea336acbb9f_1440x960.png?w=200',
                  large: 'https://screenshots.pagewitness.com/42a377be2faff6e0b90f8f2046e97ea336acbb9f_1440x960.png',
                  full: 'https://screenshots.pagewitness.com/42a377be2faff6e0b90f8f2046e97ea336acbb9f_1440x960.png'
                }
              },
              {
                id: 88872,
                key: 'Pvs90zX',
                detectedChangePercent: 0,
                step: 0,
                reference: '',
                caption: '',
                passed: false,
                url: '',
                screenShotPath: 'https://pagewitness-chromda-files.s3.amazonaws.com/b694a6be0232a36cdb561bfa09fee28cfa706cdc_375x667.png',
                viewPort: { width: 375, height: 667 },
                image: {
                  thumbnail: 'https://screenshots.pagewitness.com/b694a6be0232a36cdb561bfa09fee28cfa706cdc_375x667.png?w=200',
                  large: 'https://screenshots.pagewitness.com/b694a6be0232a36cdb561bfa09fee28cfa706cdc_375x667.png',
                  full: 'https://screenshots.pagewitness.com/b694a6be0232a36cdb561bfa09fee28cfa706cdc_375x667.png'
                }
              }
            ],
            tagItems: [],
            url: {
              id: 78,
              scheme: 'https',
              path: '/shop/snow',
              queryString: null,
              hash: null,
              entity: {
                dateCreated: '2020-08-04T01:16:34.363Z',
                dateDeleted: null,
                dateLastUpdated: '2020-08-04T01:16:34.363Z'
              },
              host: {
                id: 1,
                favIconPath: '23sds',
                domain: 'torpedo7.co.nz',
                host: 'www',
                port: 0,
                entity: {
                  dateCreated: '2020-07-01T01:26:37.910Z',
                  dateDeleted: null,
                  dateLastUpdated: '2020-07-01T01:26:38.420Z'
                }
              }
            }
          },
          {
            id: 92950,
            key: '96W4LwY',
            title: '',
            script: '58e6b3a414a1e090dfc6029add0f3555ccba127f',
            detectedChangePercent: 74,
            detectedChangeFromReferencePercent: 0,
            dateCaptured: '2020-08-21T00:00:53.000Z',
            status: '200',
            warcPath: '',
            harPath: '',
            htmlPath: '',
            captureHtml: false,
            captureHar: false,
            captureWarc: false,
            captureScreencast: false,
            passed: true,
            metaDescription:
              "Torpedo7 is New Zealand's top outdoor adventure store with the best in bike, snow, camping, water gear, outdoor clothing and more. Shop NOW!",
            documentDownloadTimeMs: 933,
            entity: {
              dateCreated: '2020-08-21T00:00:53.264Z',
              dateDeleted: null,
              dateLastUpdated: '2020-08-21T00:01:06.000Z'
            },
            screenshots: [
              {
                id: 88874,
                key: 'gApXGpj',
                detectedChangePercent: 74,
                step: 0,
                reference: '',
                caption: '',
                passed: false,
                url: '',
                screenShotPath: 'https://pagewitness-chromda-files.s3.amazonaws.com/8aab08d3e7fb8e2dba10354bb0878292bb4d66ee_375x667.png',
                viewPort: { width: 375, height: 667 },
                image: {
                  thumbnail: 'https://screenshots.pagewitness.com/8aab08d3e7fb8e2dba10354bb0878292bb4d66ee_375x667.png?w=200',
                  large: 'https://screenshots.pagewitness.com/8aab08d3e7fb8e2dba10354bb0878292bb4d66ee_375x667.png',
                  full: 'https://screenshots.pagewitness.com/8aab08d3e7fb8e2dba10354bb0878292bb4d66ee_375x667.png'
                }
              }
            ],
            tagItems: [],
            url: {
              id: 25,
              scheme: 'https',
              path: '/',
              queryString: null,
              hash: null,
              entity: {
                dateCreated: '2020-07-01T01:26:38.931Z',
                dateDeleted: null,
                dateLastUpdated: '2020-07-01T01:26:39.493Z'
              },
              host: {
                id: 1,
                favIconPath: '23sds',
                domain: 'torpedo7.co.nz',
                host: 'www',
                port: 0,
                entity: {
                  dateCreated: '2020-07-01T01:26:37.910Z',
                  dateDeleted: null,
                  dateLastUpdated: '2020-07-01T01:26:38.420Z'
                }
              }
            }
          },
          {
            id: 92951,
            key: 'uyTEqJf',
            title: '',
            script: '68d706e74df720b4deabf1c80291a8695ecdf2b8',
            detectedChangePercent: 90,
            detectedChangeFromReferencePercent: 0,
            dateCaptured: '2020-08-21T00:00:53.000Z',
            status: '200',
            warcPath: '',
            harPath: '',
            htmlPath: '',
            captureHtml: false,
            captureHar: false,
            captureWarc: false,
            captureScreencast: true,
            passed: true,
            metaDescription:
              "Torpedo7 is New Zealand's top outdoor adventure store with the best in bike, snow, camping, water gear, outdoor clothing and more. Shop NOW!",
            documentDownloadTimeMs: 895,
            entity: {
              dateCreated: '2020-08-21T00:00:53.405Z',
              dateDeleted: null,
              dateLastUpdated: '2020-08-21T00:01:21.000Z'
            },
            screenshots: [
              {
                id: 88880,
                key: '3qrpypK',
                detectedChangePercent: 74,
                step: 0,
                reference: '',
                caption: '',
                passed: false,
                url: '',
                screenShotPath: 'https://pagewitness-chromda-files.s3.amazonaws.com/8aab08d3e7fb8e2dba10354bb0878292bb4d66ee_375x667.png',
                viewPort: { width: 375, height: 667 },
                image: {
                  thumbnail: 'https://screenshots.pagewitness.com/8aab08d3e7fb8e2dba10354bb0878292bb4d66ee_375x667.png?w=200',
                  large: 'https://screenshots.pagewitness.com/8aab08d3e7fb8e2dba10354bb0878292bb4d66ee_375x667.png',
                  full: 'https://screenshots.pagewitness.com/8aab08d3e7fb8e2dba10354bb0878292bb4d66ee_375x667.png'
                }
              },
              {
                id: 88881,
                key: 'ik0vQAP',
                detectedChangePercent: 90,
                step: 1,
                reference: '',
                caption: '',
                passed: false,
                url: '',
                screenShotPath: 'https://pagewitness-chromda-files.s3.amazonaws.com/f765bc00a3a56cc9da7789d4105bf1e7a6016bc5_375x667.png',
                viewPort: { width: 375, height: 667 },
                image: {
                  thumbnail: 'https://screenshots.pagewitness.com/f765bc00a3a56cc9da7789d4105bf1e7a6016bc5_375x667.png?w=200',
                  large: 'https://screenshots.pagewitness.com/f765bc00a3a56cc9da7789d4105bf1e7a6016bc5_375x667.png',
                  full: 'https://screenshots.pagewitness.com/f765bc00a3a56cc9da7789d4105bf1e7a6016bc5_375x667.png'
                }
              },
              {
                id: 88882,
                key: 'tnTLEL8',
                detectedChangePercent: 90,
                step: 2,
                reference: '',
                caption: '',
                passed: false,
                url: '',
                screenShotPath: 'https://pagewitness-chromda-files.s3.amazonaws.com/38488832ed47f8ab606c92fb91d47eda087fffcb_375x667.png',
                viewPort: { width: 375, height: 667 },
                image: {
                  thumbnail: 'https://screenshots.pagewitness.com/38488832ed47f8ab606c92fb91d47eda087fffcb_375x667.png?w=200',
                  large: 'https://screenshots.pagewitness.com/38488832ed47f8ab606c92fb91d47eda087fffcb_375x667.png',
                  full: 'https://screenshots.pagewitness.com/38488832ed47f8ab606c92fb91d47eda087fffcb_375x667.png'
                }
              }
            ],
            tagItems: [],
            url: {
              id: 25,
              scheme: 'https',
              path: '/',
              queryString: null,
              hash: null,
              entity: {
                dateCreated: '2020-07-01T01:26:38.931Z',
                dateDeleted: null,
                dateLastUpdated: '2020-07-01T01:26:39.493Z'
              },
              host: {
                id: 1,
                favIconPath: '23sds',
                domain: 'torpedo7.co.nz',
                host: 'www',
                port: 0,
                entity: {
                  dateCreated: '2020-07-01T01:26:37.910Z',
                  dateDeleted: null,
                  dateLastUpdated: '2020-07-01T01:26:38.420Z'
                }
              }
            }
          },
          {
            id: 92954,
            key: 'yzqr1sv',
            title: '',
            script: '',
            detectedChangePercent: 100,
            detectedChangeFromReferencePercent: 0,
            dateCaptured: '2020-08-21T00:00:54.000Z',
            status: '200',
            warcPath: '',
            harPath: '',
            htmlPath: '',
            captureHtml: false,
            captureHar: false,
            captureWarc: false,
            captureScreencast: false,
            passed: true,
            metaDescription: '',
            documentDownloadTimeMs: 905,
            entity: {
              dateCreated: '2020-08-21T00:00:53.837Z',
              dateDeleted: null,
              dateLastUpdated: '2020-08-21T00:01:04.000Z'
            },
            screenshots: [
              {
                id: 88868,
                key: 'JQQTx6f',
                detectedChangePercent: 100,
                step: 0,
                reference: '',
                caption: '',
                passed: false,
                url: '',
                screenShotPath: 'https://pagewitness-chromda-files.s3.amazonaws.com/e9028e17afff7a73ba5ca5cbae2412a301400c74_375x667.png',
                viewPort: { width: 375, height: 667 },
                image: {
                  thumbnail: 'https://screenshots.pagewitness.com/e9028e17afff7a73ba5ca5cbae2412a301400c74_375x667.png?w=200',
                  large: 'https://screenshots.pagewitness.com/e9028e17afff7a73ba5ca5cbae2412a301400c74_375x667.png',
                  full: 'https://screenshots.pagewitness.com/e9028e17afff7a73ba5ca5cbae2412a301400c74_375x667.png'
                }
              }
            ],
            tagItems: [],
            url: {
              id: 85,
              scheme: 'https',
              path: '/docs/test/color.html',
              queryString: null,
              hash: null,
              entity: {
                dateCreated: '2020-08-13T06:08:23.779Z',
                dateDeleted: null,
                dateLastUpdated: '2020-08-13T06:08:23.779Z'
              },
              host: {
                id: 1,
                favIconPath: '23sds',
                domain: 'torpedo7.co.nz',
                host: 'www',
                port: 0,
                entity: {
                  dateCreated: '2020-07-01T01:26:37.910Z',
                  dateDeleted: null,
                  dateLastUpdated: '2020-07-01T01:26:38.420Z'
                }
              }
            }
          },
          {
            id: 92968,
            key: 'o6GEK6b',
            title: '',
            script: '',
            detectedChangePercent: 1,
            detectedChangeFromReferencePercent: 0,
            dateCaptured: '2020-08-22T00:00:53.000Z',
            status: '200',
            warcPath: '',
            harPath: '',
            htmlPath: '',
            captureHtml: false,
            captureHar: false,
            captureWarc: false,
            captureScreencast: false,
            passed: true,
            metaDescription:
              'Browse our range of ski and snowboard gear, snow boots and more. Huge range of brands to choose from in-store and online at great prices. Shop with us!',
            documentDownloadTimeMs: 925,
            entity: {
              dateCreated: '2020-08-22T00:00:52.812Z',
              dateDeleted: null,
              dateLastUpdated: '2020-08-22T00:01:08.000Z'
            },
            screenshots: [
              {
                id: 88899,
                key: 'TvjKQlA',
                detectedChangePercent: 1,
                step: 0,
                reference: '',
                caption: '',
                passed: false,
                url: '',
                screenShotPath: 'https://pagewitness-chromda-files.s3.amazonaws.com/ad9d69e1251a0a57a3f4c1bc4def2d5e9376c885_1440x960.png',
                viewPort: { width: 1440, height: 960 },
                image: {
                  thumbnail: 'https://screenshots.pagewitness.com/ad9d69e1251a0a57a3f4c1bc4def2d5e9376c885_1440x960.png?w=200',
                  large: 'https://screenshots.pagewitness.com/ad9d69e1251a0a57a3f4c1bc4def2d5e9376c885_1440x960.png',
                  full: 'https://screenshots.pagewitness.com/ad9d69e1251a0a57a3f4c1bc4def2d5e9376c885_1440x960.png'
                }
              },
              {
                id: 88901,
                key: 'lvqP9XA',
                detectedChangePercent: 0,
                step: 0,
                reference: '',
                caption: '',
                passed: false,
                url: '',
                screenShotPath: 'https://pagewitness-chromda-files.s3.amazonaws.com/89671c4dec6d1c9c146218c4c709256942a21644_375x667.png',
                viewPort: { width: 375, height: 667 },
                image: {
                  thumbnail: 'https://screenshots.pagewitness.com/89671c4dec6d1c9c146218c4c709256942a21644_375x667.png?w=200',
                  large: 'https://screenshots.pagewitness.com/89671c4dec6d1c9c146218c4c709256942a21644_375x667.png',
                  full: 'https://screenshots.pagewitness.com/89671c4dec6d1c9c146218c4c709256942a21644_375x667.png'
                }
              }
            ],
            tagItems: [],
            url: {
              id: 78,
              scheme: 'https',
              path: '/shop/snow',
              queryString: null,
              hash: null,
              entity: {
                dateCreated: '2020-08-04T01:16:34.363Z',
                dateDeleted: null,
                dateLastUpdated: '2020-08-04T01:16:34.363Z'
              },
              host: {
                id: 1,
                favIconPath: '23sds',
                domain: 'torpedo7.co.nz',
                host: 'www',
                port: 0,
                entity: {
                  dateCreated: '2020-07-01T01:26:37.910Z',
                  dateDeleted: null,
                  dateLastUpdated: '2020-07-01T01:26:38.420Z'
                }
              }
            }
          },
          {
            id: 92970,
            key: 'miPaw4I',
            title: '',
            script: '58e6b3a414a1e090dfc6029add0f3555ccba127f',
            detectedChangePercent: 74,
            detectedChangeFromReferencePercent: 0,
            dateCaptured: '2020-08-22T00:00:53.000Z',
            status: '200',
            warcPath: '',
            harPath: '',
            htmlPath: '',
            captureHtml: false,
            captureHar: false,
            captureWarc: false,
            captureScreencast: false,
            passed: true,
            metaDescription:
              "Torpedo7 is New Zealand's top outdoor adventure store with the best in bike, snow, camping, water gear, outdoor clothing and more. Shop NOW!",
            documentDownloadTimeMs: 1129,
            entity: {
              dateCreated: '2020-08-22T00:00:53.163Z',
              dateDeleted: null,
              dateLastUpdated: '2020-08-22T00:01:07.000Z'
            },
            screenshots: [
              {
                id: 88903,
                key: 'E59sr70',
                detectedChangePercent: 74,
                step: 0,
                reference: '',
                caption: '',
                passed: false,
                url: '',
                screenShotPath: 'https://pagewitness-chromda-files.s3.amazonaws.com/e04963f2b558867bd2c9ab019eca2e32cba8529e_375x667.png',
                viewPort: { width: 375, height: 667 },
                image: {
                  thumbnail: 'https://screenshots.pagewitness.com/e04963f2b558867bd2c9ab019eca2e32cba8529e_375x667.png?w=200',
                  large: 'https://screenshots.pagewitness.com/e04963f2b558867bd2c9ab019eca2e32cba8529e_375x667.png',
                  full: 'https://screenshots.pagewitness.com/e04963f2b558867bd2c9ab019eca2e32cba8529e_375x667.png'
                }
              }
            ],
            tagItems: [],
            url: {
              id: 25,
              scheme: 'https',
              path: '/',
              queryString: null,
              hash: null,
              entity: {
                dateCreated: '2020-07-01T01:26:38.931Z',
                dateDeleted: null,
                dateLastUpdated: '2020-07-01T01:26:39.493Z'
              },
              host: {
                id: 1,
                favIconPath: '23sds',
                domain: 'torpedo7.co.nz',
                host: 'www',
                port: 0,
                entity: {
                  dateCreated: '2020-07-01T01:26:37.910Z',
                  dateDeleted: null,
                  dateLastUpdated: '2020-07-01T01:26:38.420Z'
                }
              }
            }
          },
          {
            id: 92971,
            key: '20lbx42',
            title: '',
            script: '68d706e74df720b4deabf1c80291a8695ecdf2b8',
            detectedChangePercent: 90,
            detectedChangeFromReferencePercent: 0,
            dateCaptured: '2020-08-22T00:00:53.000Z',
            status: '200',
            warcPath: '',
            harPath: '',
            htmlPath: '',
            captureHtml: false,
            captureHar: false,
            captureWarc: false,
            captureScreencast: true,
            passed: true,
            metaDescription:
              "Torpedo7 is New Zealand's top outdoor adventure store with the best in bike, snow, camping, water gear, outdoor clothing and more. Shop NOW!",
            documentDownloadTimeMs: 935,
            entity: {
              dateCreated: '2020-08-22T00:00:53.333Z',
              dateDeleted: null,
              dateLastUpdated: '2020-08-22T00:01:16.000Z'
            },
            screenshots: [
              {
                id: 88909,
                key: '3VX4Mtg',
                detectedChangePercent: 77,
                step: 0,
                reference: '',
                caption: '',
                passed: false,
                url: '',
                screenShotPath: 'https://pagewitness-chromda-files.s3.amazonaws.com/594c2ed3ac703c4fdf7e23faa7e5de4381dbe5af_375x667.png',
                viewPort: { width: 375, height: 667 },
                image: {
                  thumbnail: 'https://screenshots.pagewitness.com/594c2ed3ac703c4fdf7e23faa7e5de4381dbe5af_375x667.png?w=200',
                  large: 'https://screenshots.pagewitness.com/594c2ed3ac703c4fdf7e23faa7e5de4381dbe5af_375x667.png',
                  full: 'https://screenshots.pagewitness.com/594c2ed3ac703c4fdf7e23faa7e5de4381dbe5af_375x667.png'
                }
              },
              {
                id: 88910,
                key: 'CdinPT4',
                detectedChangePercent: 90,
                step: 1,
                reference: '',
                caption: '',
                passed: false,
                url: '',
                screenShotPath: 'https://pagewitness-chromda-files.s3.amazonaws.com/67eaa05dbc4b6a6b99662e60a2c18e146b23c521_375x667.png',
                viewPort: { width: 375, height: 667 },
                image: {
                  thumbnail: 'https://screenshots.pagewitness.com/67eaa05dbc4b6a6b99662e60a2c18e146b23c521_375x667.png?w=200',
                  large: 'https://screenshots.pagewitness.com/67eaa05dbc4b6a6b99662e60a2c18e146b23c521_375x667.png',
                  full: 'https://screenshots.pagewitness.com/67eaa05dbc4b6a6b99662e60a2c18e146b23c521_375x667.png'
                }
              },
              {
                id: 88911,
                key: 'JXwEqbZ',
                detectedChangePercent: 90,
                step: 2,
                reference: '',
                caption: '',
                passed: false,
                url: '',
                screenShotPath: 'https://pagewitness-chromda-files.s3.amazonaws.com/9a554bdd44d0cb1c8bbc34449da9239796abca85_375x667.png',
                viewPort: { width: 375, height: 667 },
                image: {
                  thumbnail: 'https://screenshots.pagewitness.com/9a554bdd44d0cb1c8bbc34449da9239796abca85_375x667.png?w=200',
                  large: 'https://screenshots.pagewitness.com/9a554bdd44d0cb1c8bbc34449da9239796abca85_375x667.png',
                  full: 'https://screenshots.pagewitness.com/9a554bdd44d0cb1c8bbc34449da9239796abca85_375x667.png'
                }
              }
            ],
            tagItems: [],
            url: {
              id: 25,
              scheme: 'https',
              path: '/',
              queryString: null,
              hash: null,
              entity: {
                dateCreated: '2020-07-01T01:26:38.931Z',
                dateDeleted: null,
                dateLastUpdated: '2020-07-01T01:26:39.493Z'
              },
              host: {
                id: 1,
                favIconPath: '23sds',
                domain: 'torpedo7.co.nz',
                host: 'www',
                port: 0,
                entity: {
                  dateCreated: '2020-07-01T01:26:37.910Z',
                  dateDeleted: null,
                  dateLastUpdated: '2020-07-01T01:26:38.420Z'
                }
              }
            }
          },
          {
            id: 92974,
            key: 'FbYGO6h',
            title: '',
            script: '',
            detectedChangePercent: 100,
            detectedChangeFromReferencePercent: 0,
            dateCaptured: '2020-08-22T00:00:54.000Z',
            status: '200',
            warcPath: '',
            harPath: '',
            htmlPath: '',
            captureHtml: false,
            captureHar: false,
            captureWarc: false,
            captureScreencast: false,
            passed: true,
            metaDescription: '',
            documentDownloadTimeMs: 1075,
            entity: {
              dateCreated: '2020-08-22T00:00:53.828Z',
              dateDeleted: null,
              dateLastUpdated: '2020-08-22T00:01:04.000Z'
            },
            screenshots: [
              {
                id: 88897,
                key: 'zTjbT2C',
                detectedChangePercent: 100,
                step: 0,
                reference: '',
                caption: '',
                passed: false,
                url: '',
                screenShotPath: 'https://pagewitness-chromda-files.s3.amazonaws.com/c7ee5a55e0b73f3236f07720fb6d8b36bdac3d31_375x667.png',
                viewPort: { width: 375, height: 667 },
                image: {
                  thumbnail: 'https://screenshots.pagewitness.com/c7ee5a55e0b73f3236f07720fb6d8b36bdac3d31_375x667.png?w=200',
                  large: 'https://screenshots.pagewitness.com/c7ee5a55e0b73f3236f07720fb6d8b36bdac3d31_375x667.png',
                  full: 'https://screenshots.pagewitness.com/c7ee5a55e0b73f3236f07720fb6d8b36bdac3d31_375x667.png'
                }
              }
            ],
            tagItems: [],
            url: {
              id: 85,
              scheme: 'https',
              path: '/docs/test/color.html',
              queryString: null,
              hash: null,
              entity: {
                dateCreated: '2020-08-13T06:08:23.779Z',
                dateDeleted: null,
                dateLastUpdated: '2020-08-13T06:08:23.779Z'
              },
              host: {
                id: 1,
                favIconPath: '23sds',
                domain: 'torpedo7.co.nz',
                host: 'www',
                port: 0,
                entity: {
                  dateCreated: '2020-07-01T01:26:37.910Z',
                  dateDeleted: null,
                  dateLastUpdated: '2020-07-01T01:26:38.420Z'
                }
              }
            }
          },
          {
            id: 92989,
            key: 'qKwqhVT',
            title: '',
            script: '',
            detectedChangePercent: 0,
            detectedChangeFromReferencePercent: 0,
            dateCaptured: '2020-08-23T00:03:46.000Z',
            status: '',
            warcPath: '',
            harPath: '',
            htmlPath: '',
            captureHtml: false,
            captureHar: false,
            captureWarc: false,
            captureScreencast: false,
            passed: false,
            metaDescription: '',
            documentDownloadTimeMs: 0,
            entity: {
              dateCreated: '2020-08-23T00:03:45.663Z',
              dateDeleted: null,
              dateLastUpdated: '2020-08-23T00:03:45.000Z'
            },
            screenshots: [],
            tagItems: [],
            url: {
              id: 78,
              scheme: 'https',
              path: '/shop/snow',
              queryString: null,
              hash: null,
              entity: {
                dateCreated: '2020-08-04T01:16:34.363Z',
                dateDeleted: null,
                dateLastUpdated: '2020-08-04T01:16:34.363Z'
              },
              host: {
                id: 1,
                favIconPath: '23sds',
                domain: 'torpedo7.co.nz',
                host: 'www',
                port: 0,
                entity: {
                  dateCreated: '2020-07-01T01:26:37.910Z',
                  dateDeleted: null,
                  dateLastUpdated: '2020-07-01T01:26:38.420Z'
                }
              }
            }
          },
          {
            id: 92991,
            key: '0Yv7q6r',
            title: '',
            script: '58e6b3a414a1e090dfc6029add0f3555ccba127f',
            detectedChangePercent: 0,
            detectedChangeFromReferencePercent: 0,
            dateCaptured: '2020-08-23T00:03:46.000Z',
            status: '',
            warcPath: '',
            harPath: '',
            htmlPath: '',
            captureHtml: false,
            captureHar: false,
            captureWarc: false,
            captureScreencast: false,
            passed: false,
            metaDescription: '',
            documentDownloadTimeMs: 0,
            entity: {
              dateCreated: '2020-08-23T00:03:46.005Z',
              dateDeleted: null,
              dateLastUpdated: '2020-08-23T00:03:46.000Z'
            },
            screenshots: [],
            tagItems: [],
            url: {
              id: 25,
              scheme: 'https',
              path: '/',
              queryString: null,
              hash: null,
              entity: {
                dateCreated: '2020-07-01T01:26:38.931Z',
                dateDeleted: null,
                dateLastUpdated: '2020-07-01T01:26:39.493Z'
              },
              host: {
                id: 1,
                favIconPath: '23sds',
                domain: 'torpedo7.co.nz',
                host: 'www',
                port: 0,
                entity: {
                  dateCreated: '2020-07-01T01:26:37.910Z',
                  dateDeleted: null,
                  dateLastUpdated: '2020-07-01T01:26:38.420Z'
                }
              }
            }
          }
        ],
        paging: { total: 12, page: 1, size: 10, pages: 2 }
      }
    };
  },
  computed: {
    ...mapState('monitors', ['tests'])
  },
  methods: {
    async fetch({ paging, query } = {}) {
      await this.$store.dispatch('monitors/tests', {
        params: {
          ...(query ? { query } : {})
        },
        paging: paging || new Paging()
      });
    },
    filter(paging) {
      this.fetch({ paging });
    }
  },
  async created() {
    await this.fetch();
  }
};
</script>
